<template>
  <iframe class="viewer" src="/3d-viewer/loader" />
</template>
<script>
import { userOrderview } from "@/api/Login.js";

export default {
  async mounted() {
    if (window.postMessage == null) {
      if (
        confirm(
          "当前浏览器版本过低! 推荐使用新版edge浏览器访问3d查看器, 是否跳转至新版edge下载页面?"
        )
      ) {
        // history.push("/edge-download")
        window.open("https://www.microsoft.com/zh-cn/edge#goanywherebanner");
      }
      history.goBack();
    }
    console.log(window.postMessage);

    // window.addEventListener(
    //   "message",
    //   (this.binded = this.onMessage.bind(this))
    // );

    window.onmessage = (this.binded = this.onMessage.bind(this))

    // window.addEventListener(
    //   "message",()=>{
    //     this.binded = this.onMessage({
    //       source: window,
    //       data: this.dataSource
    //     })
    //   });

    // const {
    //   data: {
    //     data: { url },
    //   },
    // } = await userOrderview({
    //   accessCode: this.code,
    // });
    // console.log("url", url);

    // this.dataSource = {
    //   type: "url",
    //   url,
    //   // url:'',
    // };


    // if (this.inited) {
    //   this.load();
    // }
  },
  created(){
    this.getUrl()
    if (this.inited) {
      this.load();
    }
  },
  destroyed() {
    window.removeEventListener("message", this.binded);
  },
  computed: {
    code() {
      return this.$route.query.code;
    },
  },
  data() {
    return {
      dataSource: {},
      binded: null,
      inited: false,
      source: null,
    };
  },
  methods: {
    getUrl(){
      // const { data: res } = await userOrderview({
      //   accessCode: this.code,
      // });
      // console.log("res", res);

      // this.dataSource = {
      //   type: "url",
      //   url: res.data.url,
      // };

      this.dataSource = {
        type: "code",
        code: this.code,
      };
    },

    load(dataSource = this.dataSource, application = this.source.application) {
      console.log(application);
      application.loadDataSource(dataSource).catch((error) => {
        console.dir(error);
        alert("加载失败: " + error.message);
        try {
          application.unmount();
        } catch (e) {
          console.error(e);
        }
        // window.close();
      });
    },
    onMessage({ source, data }) {
      console.log(source); //就是 window
      console.log(data); // 就是 data
      console.log(this.dataSource);
      if (typeof data == "object") {
        if (data.type == "banyan-3d-viewer-loader-message") {
          if (data.data) {
            this.source = source;
            console.log('this.dataSource',this,this.dataSource);
            this.processMessage(data.data);
          }
        }
      }
    },
    processMessage({ type, data }) {
      console.log("process message", type, data,this.source);
      const post = (type, data) => {
        console.log("post message", type, data,this.source);
        // this.source 就是 window
        // this.source.origin 就是域名
        this.source.postMessage(
          {
            type: "banyan-3d-viewer-loader-message",
            data: {
              type,
              data,
            },
          },
          this.source.origin
        );
      };

      if (type == "mounted") {
        post("init");
      } else if (type == "inited") {
        console.log(this.dataSource);
        console.log(this);
        if (this.dataSource) {
          this.load();
        }
      } else if (type == "open") {
        if (data) {
          this.source.location.reload();
          this.dataSource = data;
        }
      } else if (type == "exit") {
        window.close();
      }
    },
  },
};
</script>
<style scoped>
.viewer {
  width: 100vw;
  height: 100vh;
  border: 0;
}
</style>